import React, { ReactNode } from 'react';

import { Alert, Block, Button, Display, Link, Modal, Panel, Separator, Text } from '@bilira-org/design';
import {
  calculateRewardPoints,
  ForceDecimal,
  getRewardTaskState,
  RewardsTasksType,
  shouldHideRewardAction,
  UserParticipationType,
} from '@bilira-org/react-utils';
import { Trans, useTranslation } from 'react-i18next';

import FormattedNumber from '@Components/common/FormattedNumber';
import { IconCustom } from '@Components/icon/CustomIcon';

import RewardTaskDetailField from './components/RewardTaskDetailField';
import { getTaskDetails, getTaskTitle } from './helpers';
import TaskExpiresAt from './TaskExpiresAt';

type Props = {
  task: UserParticipationType | RewardsTasksType;
  open: boolean;
  onClose: () => void;
};

const RewardTaskDetailModal = ({ task, open, onClose }: Props) => {
  const { t, i18n } = useTranslation();
  const taskState = getRewardTaskState(task);
  const details = getTaskDetails(task, t, i18n.language);

  return (
    <Modal open={open} onClose={onClose} title={getTaskTitle(task, t)}>
      <Panel color="neutral-200" mb="2xl">
        <Block items="center">
          <Text size="xs" color="neutral-800">
            {t('rewards.total-earned-points')}
          </Text>
          <Block row gap="sm" items="center">
            <IconCustom type="star-coin" width="size-5" height="size-5" />

            <FormattedNumber
              weight="medium"
              color="yellow-900"
              value={calculateRewardPoints(task, taskState, true)}
              formatPriceProps={{ decimal: 2, forceDecimal: ForceDecimal.STRICT_DECIMAL, defaultValue: '-' }}
            />
          </Block>
        </Block>
      </Panel>
      <Block gap="lg">
        <RewardTaskDetailField title={t('rewards.detail.description-and-rules.title')}>
          {details.description}
        </RewardTaskDetailField>

        <RewardTaskDetailField title={t('rewards.detail.rewards.title')}>{details.rewards}</RewardTaskDetailField>
        <RewardTaskDetailField title={t('rewards.detail.date.title')}>
          {(task as UserParticipationType).expires_at ? (
            <Trans
              t={t}
              i18nKey="rewards.detail.date.renew-after"
              components={{
                timer: <TaskExpiresAt size="sm" color="primary-500" task={task} format="humanized" as="span" />,
              }}
            />
          ) : (
            t('rewards.detail.date.infinite')
          )}
        </RewardTaskDetailField>
        <RewardTaskDetailField title={t('rewards.detail.eligibility.title')}>
          {details.eligibility}
        </RewardTaskDetailField>
        <Alert status="yellow">{t('rewards.detail.update-info')}</Alert>
      </Block>

      <Display show={!shouldHideRewardAction(task)}>
        <Link
          to={details.href}
          onClick={() => onClose()}
          disabled={(task as UserParticipationType).is_eligible === false}
        >
          <Button
            mt="2xl"
            size="xl"
            stretch
            variant="filled"
            disabled={(task as UserParticipationType).is_eligible === false}
          >
            {t('rewards.detail.trade-now')}
          </Button>
        </Link>
      </Display>
    </Modal>
  );
};

export default RewardTaskDetailModal;
