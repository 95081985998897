import { RewardsTasksType, RewardTaskActionType, UserParticipationType } from '@bilira-org/react-utils';
import { TFunction } from 'i18next';

export function getTaskTitle(task: UserParticipationType | RewardsTasksType, t: TFunction) {
  let title = '';

  switch (task.action) {
    case 'transaction_count': {
      const isFirstType = task.action_unit_limit === '1';

      title = t(`rewards.task-titles.${isFirstType ? '1st' : 'nth'}-${task.action_type}`, {
        number: task.action_unit_limit,
      });
      break;
    }
    case 'transaction_volume':
      title = t('rewards.task-titles.volume', { type: t(`rewards.task-titles.${task.action_type}`) });
      break;
    default:
      title = getTaskTitleByActionType(task.action_type, t, task);
      break;
  }

  return title;
}

export function getTaskTitleByActionType(
  actionType: RewardTaskActionType,
  t: TFunction,
  task?: UserParticipationType | RewardsTasksType,
) {
  const actionTypeToTitleMap: Record<string, string | (() => string)> = {
    overnight_holding: () =>
      t('rewards.task-titles.overnight-holding', { asset: task?.asset + (task?.asset === 'BMMF' ? ' Token' : '') }),
    referral: t('rewards.task-titles.referral'),
    kyc1: t('rewards.task-titles.kyc1'),
    kyc2: 'KYC 2',
    kyc3: 'KYC 3',
    crypto: t('rewards.task-titles.crypto'),
    fiat: t('rewards.task-titles.fiat'),
    fiat_or_crypto_deposit: t('rewards.task-titles.fiat_or_crypto_deposit'),
    daily_login: t('rewards.task-titles.daily_login'),
  };

  const titleGenerator = actionTypeToTitleMap[actionType];

  if (titleGenerator) {
    return typeof titleGenerator === 'function' ? titleGenerator() : titleGenerator;
  }

  return 'Unknown';
}
